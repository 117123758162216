import React, { useState, useEffect } from "react";
import { getObjectHistory } from "../services/api";

function capitalizeFirstWord(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}

const ObjectHistory = ({ authToken, objectType, objectId }) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchHistory = async () => {
      setIsLoading(true);
      try {
        const response = await getObjectHistory(
          authToken,
          objectType,
          objectId
        );
        setHistory(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHistory();
  }, [authToken, objectType, objectId]);

  const filteredHistory = history.filter(
    (item) =>
      (filter === "All" || item.activity_type === filter) &&
      (item.details.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.performed_by.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const capitalizedObjectType = capitalizeFirstWord(objectType);

  if (isLoading) return <p className="text-gray-500">Loading history...</p>;
  if (error) return <p className="text-red-500">Error: {error}</p>;

return (
  <div className="w-full px-2 sm:px-4 py-8">
    <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">
        History
      </h2>
      <div className="flex flex-col sm:flex-row items-center">
        <select
          className="border rounded py-2 px-3 mb-2 sm:mb-0 sm:mr-2"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option>All</option>
          <option>Note</option>
          <option>Task</option>
          <option>Call</option>
        </select>
        <input
          type="text"
          placeholder="Search history..."
          className="border rounded py-2 px-3 mb-2 sm:mb-0 sm:mr-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>

    <div className="bg-white shadow-md rounded-lg overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              Timestamp
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              Activity Type
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              Performed By
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredHistory.length > 0 ? (
            filteredHistory.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-normal">
                  <div className="text-sm text-gray-500">
                    {new Date(item.timestamp).toLocaleString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-normal">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {item.activity_type}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-normal">
                  <div className="text-sm text-gray-500">{item.performed_by}</div>
                </td>
                <td className="px-6 py-4 whitespace-normal">
                  <div className="text-sm text-gray-500">{item.details}</div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                No results found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

};

export default ObjectHistory;
