import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLead, editLead, getLocations } from "../services/api";

const LeadEdit = ({ authToken }) => {
  const navigate = useNavigate();
  const { leadId } = useParams();

  console.error("Lead ID from params:", leadId); // Add this line

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    location: "",
    status: "",
    source: "",
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    },
  });
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      console.log("Lead ID from params:", leadId); // Add this line

      try {
        const [fetchedLead, fetchedLocations, fetchedLeadSources] =
          await Promise.all([
            getLead(authToken, leadId),
            getLocations(authToken),
          ]);
        setFormData({
          ...fetchedLead,
          location: fetchedLead.location?.id || "",
          source: fetchedLead.source?.id || "",
          address: fetchedLead.address || {
            street: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
          },
        });
        setLocations(fetchedLocations);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch lead data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [authToken, leadId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]:
          name === "location"
            ? value === ""
              ? ""
              : parseInt(value, 10)
            : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.location === "") {
      setError("Please select a location");
      return;
    }
    try {
      const submitData = {
        ...formData,
        location:
          formData.location === "" ? null : parseInt(formData.location, 10),
        source: formData.source,
      };
      console.log("Submitting form data:", submitData);
      await editLead(authToken, leadId, submitData);
      navigate("/leads");
    } catch (error) {
      console.error("Error updating lead:", error);
      setError("Failed to update lead. Please try again.");
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Edit Lead</h2>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        {/* Name Fields */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="first_name"
            >
              First Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="first_name"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="last_name"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Contact Fields */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Location and Status */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="location"
            >
              Location
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            >
              <option value="">Select a location</option>
              {locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="status"
            >
              Status
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="new">New</option>
              <option value="contacted">Contacted</option>
              <option value="qualified">Qualified</option>
              <option value="converted">Converted</option>
              <option value="lost">Lost</option>
            </select>
          </div>
        </div>

        {/* Source and Street */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="source"
            >
              Source
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="source"
              name="source"
              value={formData.source}
              onChange={handleChange}
            >
              <option value="contact_form">Contact Form</option>
              <option value="referral">Referral</option>
              <option value="website">Website</option>
              <option value="phone">Phone</option>
            </select>
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address.street"
            >
              Street
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address.street"
              type="text"
              name="address.street"
              value={formData.address.street}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* City and State */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address.city"
            >
              City
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address.city"
              type="text"
              name="address.city"
              value={formData.address.city}
              onChange={handleChange}
            />
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address.state"
            >
              State
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address.state"
              type="text"
              name="address.state"
              value={formData.address.state}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Country and Postal Code */}
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address.country"
            >
              Country
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address.country"
              type="text"
              name="address.country"
              value={formData.address.country}
              onChange={handleChange}
            />
          </div>
          <div className="flex-1">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address.postal_code"
            >
              Postal Code
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address.postal_code"
              type="text"
              name="address.postal_code"
              value={formData.address.postal_code}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-btn-primary hover:bg-btn-primary-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Update Lead
          </button>
        </div>
      </form>
    </div>
  );
};

export default LeadEdit;
