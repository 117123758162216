import React from 'react';

const NavMenu = ({ onMenuSelect }) => {
  const menuItems = [
    {
      label: 'Leads',
      action: 'leads',
    },
    {
      label: 'Tasks',
      action: 'my-tasks',
      // submenu: [
      //   { label: 'My Tasks', action: 'my-tasks' },
      // ],
    },
  ];

  return (
    <nav className="w-full h-16 px-4 sm:px-6 lg:px-8 flex items-center">
      <ul className="flex space-x-8 py-4">
        <button
          onClick={() => onMenuSelect('/')}
          className="text-blue-500 hover:text-blue-700 font-bold focus:outline-none focus:text-blue-600 transition duration-300 pb-2 relative"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Home
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-accent-dark transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
        </button>
        {menuItems.map((item, index) => (
          <li key={index} className="relative group">
            <button
              onClick={() => onMenuSelect(item.action)}
              className="text-blue-500 hover:text-blue-700 font-bold focus:outline-none focus:text-blue-600 transition duration-300 pb-2 relative"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {item.label}
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-accent-dark transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
            </button>
            {item.submenu && item.submenu.length > 0 && (
              <ul
                className="absolute left-0 mt-2 w-56 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out z-10 delay-75 group-hover:delay-0"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {item.submenu.map((subitem, subindex) => (
                  <li key={subindex}>
                    <button
                      onClick={() => onMenuSelect(subitem.action)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition duration-300"
                      role="menuitem"
                    >
                      {subitem.label}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;