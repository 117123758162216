import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Homepage from "./components/Homepage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";

// Import company components
import CompanyList from "./components/CompanyList";
import CompanyCreate from "./components/CompanyCreate";
import CompanyDetail from "./components/CompanyDetail";
import CompanyEdit from "./components/CompanyEdit";

// Import patient components
import PatientList from "./components/PatientList";
import PatientCreate from "./components/PatientCreate";
import PatientDetail from "./components/PatientDetail";
// import PatientEdit from './components/PatientEdit';

// Import lead components
import LeadList from "./components/LeadList";
import LeadCreate from "./components/LeadCreate";
import LeadDetail from "./components/LeadDetail";
import LeadEdit from "./components/LeadEdit";

// Import opportunity components
import OpportunityList from "./components/OpportunityList";

// Import account components
import AccountList from "./components/AccountList";

// Import notes components
import NotesList from "./components/NotesList";
import NoteCreate from "./components/NoteCreate";
import NoteDetail from "./components/NoteDetail";
import MyNotes from "./components/MyNotes";
import MyTasks from "./components/MyTasks";

// Import tasks components
import TasksList from "./components/TasksList";
import TaskDetail from "./components/TaskDetail";

import ServiceList from "./components/ServiceList";
import ServiceEdit from "./components/ServiceEdit";
import ServiceCreate from "./components/ServiceCreate";

import AppointmentList from "./components/AppointmentList";
import AppointmentCreate from "./components/AppointmentCreate";
import AppointmentEdit from "./components/AppointmentEdit";

// Import info component
import Info from "./components/Info";

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const handleLogin = useCallback(
    (loginData) => {
      setAuthToken(loginData.token);
      const newUserInfo = {
        isCompanyAdmin: loginData.is_company_admin,
        companyId: loginData.company,
        locationIds: loginData.locations,
        role: loginData.role,
      };
      setUserInfo(newUserInfo);
      setIsLoggedIn(true);
      localStorage.setItem("authToken", loginData.token);
      localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      navigate("/home");
    },
    [navigate]
  );

  // const handleLogin = useCallback((token) => {
  //   setAuthToken(token);
  //   setIsLoggedIn(true);
  //   localStorage.setItem('authToken', token);
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedUserInfo = localStorage.getItem("userInfo");
    if (token && storedUserInfo) {
      setAuthToken(token);
      setUserInfo(JSON.parse(storedUserInfo));
      setIsLoggedIn(true);
    }
  }, []);

  const handleSignup = (token) => {
    setAuthToken(token);
    setIsLoggedIn(true);
    localStorage.setItem("authToken", token);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setAuthToken("");
    setUserInfo(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const onMenuSelect = (path) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-2">
              <svg className="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <span className="text-xl font-semibold">
                <span className="text-gray-800">dentara</span>
                <span className="text-blue-600">tech</span>
                <span className="text-gray-600">.com</span>
              </span>
            </Link>
            {isLoggedIn ? (
              <nav className="bg-white">
                <ul className="flex space-x-4 items-center">
                  <li>
                    <Link
                      to="/info"
                      className="text-gray-600 hover:text-gray-800"
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    {/* <Link
                      to="/settings"
                      className="text-gray-600 hover:text-gray-800"
                    >
                      Settings
                    </Link> */}
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="bg-btn-primary text-white px-3 py-1 rounded hover:bg-btn-primary-hover transition duration-300"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </nav>
            ) : (
              <div className="flex space-x-2">
                <button
                  onClick={() => navigate("/login")}
                  className="bg-btn-primary text-white px-3 py-1 rounded hover:bg-btn-primary-hover transition duration-300"
                >
                  Login
                </button>
                <button
                  onClick={() => navigate("/signup")}
                  className="bg-btn-primary text-gray-800 px-3 py-1 rounded hover:bg-btn-primary-hover transition duration-300"
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {isLoggedIn && (
          <div className="w-full mx-auto px-4">
            <NavMenu onMenuSelect={onMenuSelect} />
          </div>
      )}

      <main className="flex-grow flex flex-col bg-white">
        <div className="flex-grow container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Routes>
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <PrivateRoute isAuthenticated={isLoggedIn}>
                    <Homepage authToken={authToken} />
                  </PrivateRoute>
                ) : (
                  <Login onLogin={handleLogin} />
                )
              }
            />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route
              path="/signup"
              element={<Signup onSignup={handleSignup} />}
            />
            <Route
              path="/home"
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  <Homepage authToken={authToken} />
                </PrivateRoute>
              }
            />

            {/* Company Routes */}
            <Route
              path="/companies"
              element={
                <PrivateRoute isAuthenticated={isLoggedIn}>
                  <CompanyList authToken={authToken} />
                </PrivateRoute>
              }
            />
            <Route
              path="/companies/create"
              element={<CompanyCreate authToken={authToken} />}
            />
            <Route
              path="/companies/:id"
              element={<CompanyDetail authToken={authToken} />}
            />
            <Route
              path="/companies/:id/edit"
              element={<CompanyEdit authToken={authToken} />}
            />

            {/* Patient Routes */}
            <Route
              path="/patients"
              element={<PatientList authToken={authToken} />}
            />
            <Route
              path="/patients/create"
              element={<PatientCreate authToken={authToken} />}
            />
            <Route
              path="/patients/:id"
              element={<PatientDetail authToken={authToken} />}
            />
            {/* <Route path="/patients/:id/edit" element={<PatientEdit authToken={authToken} />} /> */}

            {/* Lead Routes */}
            <Route path="/leads" element={<LeadList authToken={authToken} />} />
            <Route
              path="/leads/create"
              element={<LeadCreate authToken={authToken} />}
            />
            <Route
              path="/leads/:id"
              element={<LeadDetail authToken={authToken} />}
            />
            <Route
              path="/leads/:leadId/edit"
              element={<LeadEdit authToken={authToken} />}
            />

            {/* Opportunity Routes */}
            <Route
              path="/opportunities"
              element={<OpportunityList authToken={authToken} />}
            />

            {/* Account Routes */}
            <Route
              path="/accounts"
              element={<AccountList authToken={authToken} />}
            />

            {/* note routes */}
            <Route
              path="/patients/:patientId/notes"
              element={<NotesList authToken={authToken} />}
            />
            <Route
              path="/patients/:patientId/notes/:noteId"
              element={<NoteDetail authToken={authToken} />}
            />

            <Route
              path="/leads/:patientId/notes"
              element={<NotesList authToken={authToken} />}
            />
            <Route
              path="/leads/:patientId/notes/:noteId"
              element={<NoteDetail authToken={authToken} />}
            />
            <Route
              path="/my-notes"
              element={<MyNotes authToken={authToken} />}
            />

            {/* task routes */}
            <Route
              path="/patients/:patientId/tasks"
              element={<TasksList authToken={authToken} />}
            />
            <Route
              path="/patients/:patientId/tasks/:taskId"
              element={<TaskDetail authToken={authToken} />}
            />

            <Route
              path="/leads/:patientId/tasks"
              element={<TasksList authToken={authToken} />}
            />
            <Route
              path="/leads/:patientId/tasks/:taskId"
              element={<TaskDetail authToken={authToken} />}
            />

            <Route
              path="/my-tasks"
              element={<MyTasks authToken={authToken} />}
            />
            <Route
              path="/tasks/:taskId"
              element={<TaskDetail authToken={authToken} />}
            />

            {/* services routes */}
            <Route
              path="/companies/:companyId/services"
              element={<ServiceList authToken={authToken} />}
            />
            <Route
              path="/companies/:companyId/services/:serviceId/edit/"
              element={<ServiceEdit authToken={authToken} />}
            />
            <Route
              path="/companies/:companyId/services/new"
              element={<ServiceCreate authToken={authToken} />}
            />

            {/* Appointment routes */}
            <Route
              path="/patients/:patientId/appointments/"
              element={<AppointmentList authToken={authToken} />}
            />
            <Route
              path="/patients/:patientId/appointments/new"
              element={<AppointmentCreate authToken={authToken} />}
            />
            <Route
              path="/patients/:patientId/appointments/:appointmentId/edit"
              element={<AppointmentEdit authToken={authToken} />}
            />

            {/* info routes */}
            <Route path="/info" element={<Info authToken={authToken} />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AppContent;
